<template>
  <div class="card-compare-investment-chart">
    <div class="title-comparativo"><h3>Comparativo de Rendimentos</h3></div>
    <div class="back-action" @click="back()"><b-icon-arrow-left-short class="icon" /> Voltar para cenários</div>
    <bar-chart :chart-data="datacollection" :styles="myStyles" :options="options"></bar-chart>
  </div>
</template>

<script lang="ts">
import { } from 'bootstrap-vue'
import BarChart from '@/components/charts/Bar'

export default {
  name: 'CardCompareInvestmentComponent',

  startDate: null,
  endDate: null,
  isCloseable: false,

  components: {
    BarChart,
  },

  props: [
    'data',
  ],
  emits: [
    'onBack',
  ],

  mounted() {
    this.fillData()
    this.renderChart(this.chartData, this.options)
  },

  computed: {
    myStyles() {
      return {
        height: '350px',
      }
    },
  },

  data() {
    return {
      datacollection: {},
      options: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        pieceLabel: {
          mode: 'value',
          precision: 1,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
        },
      },
    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: ['Poupança', 'Valorização pelo CDI', 'Rendimento Anual Selecionado'],
        datasets: [
          {
            label: 'R$',
            backgroundColor: ['gray', 'gray', 'orange'],
            data: this.data,
            barThickness: [50, 50, 50],
            borderRadius: 5,
            borderSkipped: false,
            borderWidth: 2,
          },
        ],
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    back() {
      this.$emit('onBack')
    },
  },
}
</script>

<style>

    .card-compare-investment-chart {
      width: 100%;
      display: inline-block;
    }

    .card-compare-investment-chart .title-comparativo {
      float: left;
      margin-bottom: 20px;
    }

    .card-compare-investment-chart .title-comparativo h3 {
     font-weight: 800 !important;
    }

    .card-compare-investment-chart .back-action {
      float: right;
      font-size: 0.9rem;
      font-weight: 800;
      cursor: pointer;
    }

    .card-compare-investment-chart .back-action:hover {
      color: orange;
    }

    @media (max-width: 1000px) {
      .card-compare-investment-chart {
        margin-top: 5em !important;
      }
    }

</style>
