import Vue from 'vue'

const CalcInvestmentService = {

  getOpenedOpportunities(filter) {
    const url = '/api/services/app/CalcInvestmentService/Calc'
    return Vue.prototype.$http.post(url, filter)
  },
}

export default CalcInvestmentService
