<template>
  <div class="calc-page">
    <ImageCalcInvest />

    <div class="site-container site-container-margin site-container-size">
      <CardCalcInvestment :filter="filter" @onSimulate="simulate" @onBeginSimulate="beginSimulate" />
    </div>

    <div class="item margin_top" v-if="!viewDetails && !isLoading">
      <CardCompareInvestment v-for="item in items" :item="item" @onDetails="details()" />
    </div>

    <div class="site-container site-container-size" v-if="viewDetails && !isLoading">
      <div class="margin">
        <CardCompareInvestmentChart @onBack="backChart()" :data="dataChart" />
      </div>
    </div>

    <b-overlay
      variant="white"
      :show="isLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    />

    <Footer />
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import Footer from '../components/parts/footer/Footer.vue'
import ImageCalcInvest from '../components/images/ImageCalcInvest.vue'
import CardCalcInvestment from '../components/CalcInvestment.vue'
import CardCompareInvestment from '../components/CardCompareInvestment.vue'
import CardCompareInvestmentChart from '../components/CardCompareInvestmentChart.vue'

export default {
  name: 'SiteOportunityDetail',

  components: {
    Footer,
    ImageCalcInvest,
    CardCalcInvestment,
    CardCompareInvestment,
    CardCompareInvestmentChart,
    BOverlay,
  },

  created() {
    const localData = localStorage.getItem('calcInvestment')
    if (localData) {
      const data = JSON.parse(localData)
      this.filter = data.filter
      this.items = data.data
      localStorage.removeItem('calcInvestment')
    }
  },

  data() {
    return {
      isLoading: false,
      dataChart: [],
      items: [],
      viewDetails: false,
      compareInvestments: [],
      filter: {
        valueInvested: 0,
        valueRentability: 0,
      },
    }
  },

  methods: {
    beginSimulate() {
      this.isLoading = true
    },
    simulate(data) {
      this.items = data.data
      this.dataChart = this.items.map(x => x.valueYield)
      this.isLoading = false
    },
    backChart() {
      this.viewDetails = false
    },
    details() {
      this.dataChart = this.items.map(x => x.valueYield)
      this.viewDetails = true
    },
  },
}
</script>

<style scoped>

.margin {
  padding-right: 80px;
  padding-left: 80px;
  margin-bottom: 100px;
}
.site-container-size {
  width: 100%;
}

.site-container-margin {
  margin-top: -270px;
}

.calc-page .item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

</style>
