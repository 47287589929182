<template>
  <div class="card-compare-investment">
    <div class="title">{{ item.category }}</div>
    <div class="label">Total Investido:</div>
    <div class="value value-minor">
        {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valueInvested) }}
    </div>
    <div class="label">Rendimento:</div>
    <div class="value">
        {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valueYield) }}
    </div>
    <b-button @click="details()">Ver detalhes</b-button>
  </div>
</template>

<script lang="ts">
import { BButton } from 'bootstrap-vue'

export default {

  name: 'CardCompareInvestmentComponent',

  components: {
    BButton,
  },

  props: [
    'item',
  ],
  emits: [
    'onDetails',
  ],

  data() {
    return {
    }
  },

  methods: {
    details() {
      this.$emit('onDetails')
    },
  },
}
</script>

<style>

   .card-compare-investment {
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      margin: 20px;
      width: 300px;
      height: 320px;
      box-shadow: 0 0 1em rgba(0, 0, 0, .1);
    }

    .card-compare-investment .title {
      font-size: small;
      color: rgb(206, 134, 0);
      background-color: rgb(255, 220, 155);
      border-radius: 20px;
      padding: 3px 10px 3px 8px;
      margin-bottom: 30px;
    }
    .card-compare-investment .label {
      font-size: .9em;
      font-weight: 200;
    }
    .card-compare-investment .value {
        font-size: xx-large;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .card-compare-investment button:hover,
    .card-compare-investment button:active {
      background-color: orange !important;
      border-color: orange !important;
      color: rgb(71, 41, 3) !important;
    }

    .card-compare-investment .value-minor {
      font-size: x-large;
    }

    .card-compare-investment button {
      background-color: transparent !important;
      color: gray !important;
      border: 1px solid gray !important;
      border-radius: 20px;
      margin-top: 20px;
      padding: 5px 10px 5px 10px !important;
      font-size: small;
    }

    .slider {
      width: 100%;
      background: orange;
    }
</style>
