<template>
  <div>
    <div class="card-investment">
      <div class="title"><div class="icon">$</div>Calculadora de Investimentos</div>
      <div class="itens">
        <div class="item">
          <div>
            <div class="label">Quanto quer investir?</div>
            <div class="input text-data">
                <b-input-group prepend="R$" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input ref='money' class="calc-text-value" min="0.01" v-model.lazy="data.valueInvested" v-money="money" @blur="update" :disabled="disabled" />
              </b-input-group>
            </div>
          </div>
        </div>
        
        <div class="item">
          <div>
            <div class="label margin_top">Rentabilidade anual:</div>
            <span class="calc-text-data calc-text-value">{{ data.valueRentability }}%</span>
            <b-form-input type="range" id="rentabilityRange" min="0" max="100" v-model="data.valueRentability"></b-form-input>
          </div>
        </div>
        <div class="item simulate margin_top">
          <b-button @click="simulate()">Simular</b-button>
        </div>
      </div>
    </div>
    <div style="clear:both" />
  </div>
</template>

<script lang="ts">
import { BFormInput, BButton, BInputGroup } from 'bootstrap-vue'
import CalcInvestmentService from '../services/CalcInvestmentService'
import { VMoney } from 'v-money'
import formatter from '@/mixins/formatter'

export default {

  name: 'CalcInvestmentComponent',

  components: {
    BFormInput,
    BButton,
    BInputGroup,
  },
  mixins:[formatter],
  directives: { money: VMoney },

  props: {
    filter: null
  },
  emits: [
    'onSimulate',
    'onBeginSimulate',
  ],

  data() {
    return {
      data: {
        valueInvested: 0,
        valueRentability: 0,
      },
      price: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },

  created() {
    if (this.filter) {
      this.data = this.filter
    }
  },

  methods: {
    async simulate() {
      this.$emit('onBeginSimulate')
      const values = {
        valueInvested: this.data.valueInvested.replace('.','').replace(',','.'),
        valueRentability: this.data.valueRentability
      }
      const response = await CalcInvestmentService.getOpenedOpportunities(values)
      const ret = {
        data: response.data.result,
        filter: this.data,
      }
      this.$emit('onSimulate', ret)
    },
    update($event){
      this.$emit('update', formatter.formatMaskMoney($event.currentTarget._value))
    }
  },

  watch: {
    value: {
      handler(val) {
        const el = document.getElementById('')
        if(el === null){
          val = val * 100
        }
        this.price = val
        const value = this.formatCurrencyPrice(this.price)
        const refss = this.$refs['money']
        el.value = value
      },
      immediate: true,
    },
  },
}
</script>

<style>

   .card-investment {
      background-color: white;
      border-radius: 10px;
      padding: 30px 50px 30px 50px;
      margin: -250px auto 30px auto;
      height: 300px;
    }

    .card-investment .calc-text-value {
      font-weight: 600;
      font-size: 1.2em;
    }

    .card-investment .calc-text-data {
      margin: 0px;
    }

    .card-investment .custom-range {
      height: 16px
    }

    .card-investment .title {
      font-size: x-large;
      font-weight: 600;
    }
    .card-investment .label {
      margin: 10px 0px 10px 0px;
      font-size: .9em;
      font-weight: 400;
    }
    .card-investment .title .icon {
      border-radius: 50%;
      border: 1px solid;
      width: 25px;
      height: 25px;
      margin: 5px 10px 0px 0px;
      float: left;
      text-align: center;
      font-size: medium;
      font-weight: 800;
    }

    .card-investment .itens {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
    }
    .card-investment .itens .item {
      margin-right: 20px;
      width: calc(33% - 20px);
      height: 60px;
    }

    .card-investment .simulate {
      padding-top: 25px;
    }
    .card-investment .simulate button {
      background-color: orange !important;
      border: none !important;
      width: 100%;
      padding: 20px;
    }

    .slider {
      width: 100%;
      background: orange;
    }
    @media (max-width: 1000px) {
      .itens {
        display: grid !important;
      }
      .item {
        width: 100% !important;
      }
      .margin_top {
        margin-top: 3em !important;
      }
      .simulate {
        margin-bottom: 3em;
      }
    }
</style>
